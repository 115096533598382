import { useEffect, useState, useRef, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { CaretDownIc, MenuIc } from '@dsch/dd-icons';

import { BaseBadge } from 'components/Toolkit/BaseBadge/BaseBadge';

type HeaderVariant = 'DEFAULT' | 'TRANSPARENT';

export interface HeaderUserProfileProps {
  forwardRef?: React.ForwardedRef<HTMLInputElement>;
  username: string;
  /** URL to asset for profile image */
  profileImage: string;
  notificationCount?: number;
  posX: (t: number) => void;
  handleClick: (e: React.MouseEvent) => void;
  isActive: boolean;
  handleActive: () => void;
  isNotLoggedIn: boolean;
  variant: HeaderVariant;
  className?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.button`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

const Profile = styled.div`
  position: relative;
`;

const Name = styled.span<{ variant: HeaderVariant }>`
  display: none;

  @media (min-width: 840px) {
    display: initial;
    ${({ theme }) => theme.fontSize.M16};
    margin: ${({ theme }) => `0 ${theme.spacing.S4}`};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme, variant }) =>
      variant === 'TRANSPARENT'
        ? theme.colors.WHITE
        : theme.colors.GREY_DARKER};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SIoMenu = styled(MenuIc)`
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const StyledBadge = styled(BaseBadge)`
  position: absolute;
  top: -3px;
  right: -3px;
`;

const HeaderUserProfile = forwardRef<HTMLButtonElement, HeaderUserProfileProps>(
  (
    {
      username,
      profileImage,
      notificationCount,
      posX,
      handleClick,
      isActive,
      handleActive,
      isNotLoggedIn,
      variant,
      className,
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const [isMounted, setIsMounted] = useState<boolean>(true);

    useEffect(() => {
      function updatePos() {
        if (innerRef?.current) {
          posX(innerRef.current.getBoundingClientRect().right);
        }
      }
      if (isActive) {
        window.addEventListener('resize', updatePos);
        updatePos();
        handleActive();
      } else {
        if (isMounted) {
          setIsMounted(false);
        } else {
          window.removeEventListener('resize', updatePos);
          handleActive();
        }
      }
    }, [isActive]);

    return (
      <Container ref={innerRef} className={className}>
        <Wrapper
          ref={ref}
          onClick={handleClick}
          data-testid="header-dropdown-btn"
        >
          {isNotLoggedIn ? (
            <SIoMenu
              color={
                variant === 'TRANSPARENT'
                  ? theme.colors.WHITE
                  : theme.colors.GREY_DARKER
              }
            />
          ) : (
            <>
              <Profile>
                {notificationCount && notificationCount > 0 ? (
                  <StyledBadge count={notificationCount} />
                ) : null}
                <img src={profileImage} alt="" width="24" height="24" />
              </Profile>
              <Name data-testid="user-name" variant={variant}>
                {username}
              </Name>
              <CaretDownIc
                color={
                  variant === 'TRANSPARENT'
                    ? theme.colors.WHITE
                    : theme.colors.BLUE
                }
                width="16"
                height="16"
              />
            </>
          )}
        </Wrapper>
      </Container>
    );
  },
);

HeaderUserProfile.displayName = 'HeaderUserProfile';

export { HeaderUserProfile };
