import type { RefObject } from 'react';
import { useEffect } from 'react';

type TUseClickOutside<T> = {
  /** Determines whether listener is added or removed */
  active: boolean;
  /** The component ref that clicks outside are being listened for */
  ref: RefObject<T>;
  /** The callback for when a click outside occurs */
  onClickOutside: () => void;
};

/**
 * A hook that listens for clicks outside of a ref and calls back
 */
const useClickOutside = <T,>({
  active,
  ref,
  onClickOutside,
}: TUseClickOutside<T>) => {
  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active]);

  const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
    if (
      ref.current &&
      (ref.current as unknown as HTMLElement).contains(event.target as Node)
    ) {
      return;
    }
    onClickOutside();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClickOutside();
      // Ensure focus does not persist once action is completed
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };
};

export { useClickOutside };
